// import { BookingRule, GetBookingResponse } from '@/lib/server/bookings'
import { createContext } from 'react'

export type DimensionsContextType = {
    width: number,
    height: number,
    top: number,
    left: number,
    x: number,
    y: number,
    right: number,
    bottom: number,
    mobilePixelWidth: number,
    isMobile: boolean,
    isTablet: boolean,
}

export const DimensionsContext = createContext<DimensionsContextType>({} as DimensionsContextType)
