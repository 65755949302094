import { useCallback, useEffect, useRef, useState } from 'react';
// Create a hook to seperate out logic.

export const useHubspotChat = () => {
  const { HUBSPOT_COMPANY_ID } = process.env;

  const portalId: string = HUBSPOT_COMPANY_ID as string;
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const [activeConversation, setActiveConversation] = useState(false);
  const eventRef = useRef<any>();

  useEffect(() => {
    window.hsConversationsOnReady = [() => setHasLoaded(true)];
  }, []);

  useEffect(() => {
    // Add event listener.
    if (window.HubSpotConversations) {
      setHasLoaded(true);
    }

    // Create script component.
    const script = document.createElement('script');
    script.src = `//js.hs-scripts.com/${portalId}.js`;
    script.async = true;

    document.body.appendChild(script);

    setTimeout(() => setHasLoaded(true), 1500);

    return () => {
      document.body.removeChild(script);
      window.hsConversationsOnReady = [];
    };
  }, []);

  // Subscripe to conversation events.
  useEffect(() => {
    eventRef.current = (payload: any) => {
      setActiveConversation(payload.conversation.conversationId);
    };

    if (hasLoaded) {
      window?.HubSpotConversations?.on('conversationStarted', eventRef.current);
    }

    return () => {
      window?.HubSpotConversations?.off(
        'conversationStarted',
        eventRef.current,
      );
    };
  }, [hasLoaded]);

  const openHandler = useCallback(() => {
    if (hasLoaded) {
      window?.HubSpotConversations?.widget.open();
    }
  }, [hasLoaded]);

  const closeHandler = useCallback(() => {
    if (hasLoaded) {
      window?.HubSpotConversations?.widget.close();
    }
  }, [hasLoaded]);

  const remove = useCallback(() => {
    if (hasLoaded) {
      window?.HubSpotConversations?.widget?.remove();
    }
  }, [hasLoaded]);

  const reload = useCallback(() => {
    if (hasLoaded) {
      window?.HubSpotConversations?.widget.load();
    }
  }, [hasLoaded]);

  return {
    hasLoaded,
    activeConversation,
    openHandler,
    closeHandler,
    remove,
    reload,
  };
};
