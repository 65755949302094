/* eslint-disable no-shadow */
import '@/styles/globals.css';
import '@talent-garden/react-components/main.css';
import '@talent-garden/react-components/theme.css';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { ReactNode } from 'react';
import { useEffect, useMemo, useRef } from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { NextPage } from 'next';
import { SessionProvider } from 'next-auth/react';
import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import type { GlobalVariableProps } from '@/lib/globals';
import { globalVariable } from '@/lib/globals';
import { useHubspotChat } from '@/hooks/useHubspotChat';
import { useDimensions } from '@/hooks/useDimensions';
import { DimensionsContext } from '@/context/dimensions';

type GetLayout = (page: ReactNode) => ReactNode;

// eslint-disable-next-line @typescript-eslint/ban-types
type Page<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: GetLayout;
};

// eslint-disable-next-line @typescript-eslint/ban-types
type LearnAppProps<P = {}> = AppProps<P> & {
  Component: Page<P>;
};

const MOBILE_PIXEL_WIDTH = 768;
const TABLET_PIXEL_WIDTH = 960;

const Learn = ({
  Component,
  pageProps: { session = undefined, ...pageProps },
}: LearnAppProps) => {
  const getLayout = Component.getLayout || ((page: ReactNode) => page);
  const [ref, dimensions] = useDimensions({ liveMeasure: false });

  const queryClient = useRef(
    new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          refetchOnMount: false,
          staleTime: 10 * (60 * 1000), // 10 mins
          cacheTime: 15 * (60 * 1000), // 15 mins
          retry: 1,
        },
      },
    }),
  );

  useEffect(() => {
    const host =
      typeof window !== 'undefined' && window.location.origin
        ? window.location.origin
        : '';

    let theme = 'tag';
    if (host?.includes('learn.hyperisland')) {
      theme = 'hi';
    }
    if (host?.includes('tsh.talentgarden')) {
      theme = 'tsh';
    }
    if (host?.includes('tsh.hyperisland')) {
      theme = 'hypertsh';
    }

    globalVariable.theme = theme as GlobalVariableProps['theme'];
  }, []);

  const dimensionValues = useMemo(() => {
    return {
      ...dimensions,
      mobilePixelWidth: MOBILE_PIXEL_WIDTH,
      isMobile: dimensions?.width < MOBILE_PIXEL_WIDTH,
      isTablet: dimensions?.width < TABLET_PIXEL_WIDTH,
    };
  }, [dimensions]);

  useHubspotChat();

  return (
    <SessionProvider session={session}>
      <QueryClientProvider client={queryClient.current}>
        <DimensionsContext.Provider
          // eslint-disable-next-line react/jsx-no-constructed-context-values
          value={{ ...dimensionValues }}
        >
          <div ref={ref}>
            {getLayout(<Component {...pageProps} ref={ref} />)}
          </div>
        </DimensionsContext.Provider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </SessionProvider>
  );
};

export default appWithTranslation(Learn);
