import {
  useState,
  useCallback,
  useLayoutEffect,
  // useRef
} from 'react';

export interface DimensionObject {
  width: number;
  height: number;
  top: number;
  left: number;
  x: number;
  y: number;
  right: number;
  bottom: number;
}

export type UseDimensionsHook = [
  (node: HTMLDivElement) => void,
  DimensionObject,
  HTMLElement,
];

export interface UseDimensionsArgs {
  liveMeasure?: boolean;
}

const getDimensionObject = (node: HTMLElement): DimensionObject => {
  const rect = node.getBoundingClientRect() as any;

  return {
    width: rect.width,
    height: rect.height,
    top: 'x' in rect ? rect.x : rect?.top,
    left: 'y' in rect ? rect.y : rect?.left,
    x: 'x' in rect ? rect.x : rect?.left,
    y: 'y' in rect ? rect.y : rect?.top,
    right: rect.right,
    bottom: rect.bottom,
  };
};

export const useDimensions = ({
  liveMeasure = true,
}: UseDimensionsArgs = {}): UseDimensionsHook => {
  const [dimensions, setDimensions] = useState<DimensionObject>();
  const [node, setNode] = useState<HTMLElement>();

  const ref = useCallback((node: HTMLDivElement) => {
    setNode(node);
  }, []);

  useLayoutEffect(() => {
    if (node) {
      const measure = () =>
        window.requestAnimationFrame(() =>
          setDimensions(getDimensionObject(node)),
        );
      measure();

      if (liveMeasure) {
        window.addEventListener('resize', measure);
        window.addEventListener('scroll', measure);

        return () => {
          window.removeEventListener('resize', measure);
          window.removeEventListener('scroll', measure);
        };
      }
    }
  }, [node]);

  return [ref, dimensions as DimensionObject, node as HTMLElement];
};
